import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import schedule from "../images/youth-sched.png"
import IconBlack from "../images/icon-black.png";
import image from "../images/member-of-the-month/image1.jpg"
import image2 from "../images/member-of-the-month/image2.png"
import image3 from "../images/member-of-the-month/image3.png"
import image4 from "../images/member-of-the-month/image4.png"
import image5 from "../images/member-of-the-month/image5.png"
import image6 from "../images/member-of-the-month/image6.png"
import image7 from "../images/member-of-the-month/image7.png"
import image8 from "../images/member-of-the-month/image8.png"
import image9 from "../images/member-of-the-month/image9.png"
import image10 from "../images/member-of-the-month/image10.png"
import image11 from "../images/member-of-the-month/image11.png"
import image12 from "../images/member-of-the-month/image12.png"


function MemberOfTheMonth(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Member of the Month</title>
            </Helmet>
            <Nav/>
            <div className="lg:pt-12 pb-12 bg-gray-100">
                {/* Code block starts */}

                <div className="w-full px-6">
                    <div className="mt-8 relative rounded-lg bg-gradient-to-r from-accent to-[#20a264] container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
                        <img className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg" />
                        <img className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg" />
                        <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">Member of the Month!</h1>
                        </div>
                        <img className={"w-28 pb-10"} src={IconBlack} alt={"icon"}/>

                    </div>
                    <div className="container mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
                        <div className="relative sm:w-2/3 w-11/12">
                            <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto lg:px-8 pt-8 text-lg bg-white rounded-xl"}>
                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>January 2024 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image12} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for January is Natalie. Here's a bit about what she has to say about how Kinetic Impact helped her: "...The car accident gave me nerve symptoms going down my left side, making it painful to even open a window. Having a seven-year-old, normal everyday tasks were hard. <br/><br/>"When asked about my goals, one of them was to open a window without pain. I worked with Kinetic Impact to strengthen my shoulder girdle and alleviate pain, which was my primary focus. Once I started becoming stronger, the symptoms started to go away, I would say in about 3 months, then I started adding group classes. <br/><br/>"My next goal is to do a push-up. I can do many push-ups in a row now and am working on pull-ups, which I've never been able to do because I’m hypermobile. Pilates was good for my core and recovery, but I definitely needed something more, and got a lot of that through Kinetic Impact...."
                                    <a className={"text-primary"} href={"https://www.youtube.com/watch?v=Eg-s-vcKzaw"}> click here to learn all about her great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>December 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image11} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for December is Mike. Here's a bit about what he has to say about how Kinetic Impact helped him: "I had an episode where I hurt my back while playing golf in a tournament. I strained a back muscle trying to get out of a bunker, and it immobilized me for close to a month. I guess the biggest thing I didn't realize I lacked was balance, especially in terms of walking the plank or some of the one-foot exercises. From a consistency standpoint, it has helped with my swing and balance. Cardio is a lot better now, so I can play maybe two or three times a week without any fatigue. Before, I was probably fatigued after two rounds of golf, but now I feel great...."
                                    <a className={"text-primary"} href={"https://www.youtube.com/watch?v=ZICU3JBTXTQ"}> click here to learn all about his great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>November 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image10} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for November is Matt. Here's a bit about what he has to say about how Kinetic Impact helped him: "Hi, I'm Matt, and I've been working with Kinetic Impact for probably a couple of years at least. It started with a particular issue with my back and then morphed into more of a focus on flexibility and strength.<br/><br/>
                                    Sometimes people perceive chiropractors as they’ll adjust you all day, but this is a heck of a lot more than that. This is functional strength and creating long-term stability in your body, you can see it in the exercises we do. To me I see a difference in how my body feels, you can start to identify the real pain points and become more transparent and vulnerable to the whole process. You kind of get all in on it and then it feeds on itself and you continue to want to come in and see the work and the progress. It inspires you not only to get physically fit but it also inspires you to eat better, and I much prefer this to taking a medication or something like that. You control it, I have complete control over my health here..."
                                    <a className={"text-primary"} href={"https://www.youtube.com/watch?v=-jzjj7utkr0"}> click here to learn all about his great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>October 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image9} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for October is Joe. Here's a bit about what he has to say about how Kinetic Impact helped him: "I was involved in a head-on collision where the other driver crossed lanes and hit me head on. I ended up in shock trauma for three weeks, with two of those weeks in the ICU. In total, I spent 16 weeks in the hospital before coming back here. Within a month of returning, I started working with Kevin. Kevin understood everything, and he knew what I had gone through. He knew what to push and what not to push. By doing that, he enabled me to help other parts of my body get stronger, helped the weaker parts. It was a big plus. The inspiration of this place, and people doing things to help themselves, to be better at it, brought me back to a time when I was excited about training for athletics. This place is great, the people are great, and I'll probably keep coming here for the rest of my life...",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=w9W5BLejTe8"}> click here to learn all about his great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>September 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image8} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for September is Will. Here's a bit about what he has to say about how Kinetic Impact helped him: "My name is Will, I'm from West Coast Martial Arts Academy in Forest Ranch, I got injured four years ago doing a backflip ... The longer I waited the more things got worse ... where I didn't think I'd be able to do anything again or I was going to risk trying to do surgery. Finally, I came in here, worked with Kevin who was able to get me at least on a plan with functional exercises I could do to help me improve my strength and mobility and get back to where I could actually start teaching again...",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=oSGH1qwyIvk"}> click here to learn all about their great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>August 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image7} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for August is Chad. Here's a bit about what he has to say about how Kinetic Impact helped him: "I'm an ex-pro athlete, I played basketball in Europe and had a surgery a few years ago for my hip and it completely messed me up and threw me back where I wasn't able to walk for almost two years and had an identity crisis and the worst time of my life for sure mentally, physically and went through a crazy journey and obviously I'm sitting here because of kinetic impact and what [they] have been able to do for me...",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=8-AGZvQNLvw"}> click here to learn all about their great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>June 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image6} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new member of the month for June is Basil. Here's a bit about what he has to say about how Kinetic Impact helped him: "I'm a hair stylist and I'm on my feet a lot and all of a sudden I went though a period were my back was hurting constantly and I didn't really know what it was and I'd heard about Ben and kinetic impact and so I thought I'd give him a call...  and I didn't have any pain after about three or four sessions...",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=A4qsOlFt7bk"}> click here to learn all about their great experience working with Kinetic Impact on YouTube!</a> </p>
                                <div className={"pb-24"}></div>


                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>May 2023 Awesome Humans of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image5} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our new members of the month for May are Torie & Trina. Here's a bit about what they have to say about how Kinetic Impact helped them: "When we're lifting weights I'm told what I'm doing wrong and I really like that, so it doesn't cause any injury, because before I would do it wrong but I wouldn't know I would do it wrong. Now I've improved so much because of weight training",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=tHPCZ0MbM58"}> click here to learn all about their amazing journey on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>April 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image4} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Our newest member of the month for April is Alane. Here's a bit about what she has to say about how Kinetic Impact helped her: "I just figured I'd end up with a hip replacement... [But after going to Kinetic Impact] The hip pain that I came in for was gone, it was gone in about two weeks!",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=4fbUg4snf4U"}> click here to learn all about her amazing journey on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>March 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image3} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Please welcome our newest member of the month! His name is Scott Heyn! Here's a bit about what he has to say about why he originally come in to Kinetic Impact: "I've had lower back pain for several years on and off but it was kind of manageable, it would just flare up here and there, it would go away. This time it was kind of consistent and that it was getting worse and it was to the point where I was tired of worrying about it, what I call managing my golf right. So I had to worry if I play too many days in a row or practice too much to make sure I could kind of compete at a high level and then what I was finding was I wasn't really swinging and playing the way I wanted to...",<a className={"text-primary"} href={"https://www.youtube.com/watch?v=U_pneld-w5k"}> learn all about his amazing recovery on YouTube!</a> </p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>February 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image2} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Meet our newest member of the month! His name is Carlos! Carlos is originally from Portugal and moved to San Diego from Colorado. He came to Kinetic Impact experiencing debilitating neck pain and headaches that kept him from doing the things he loved most. Carlos enjoys the outdoors hiking, biking, and skiing when he can get to snow. </p>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>When Carlos first came to Kinetic Impact he was unable to do any of those activities. After building confidence in his body again Carlos is now back at it and more confident than ever! Carlos just finished a 3 day hiking expedition in Catalina Island where he was able to hike over 5 hours every single day.</p>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>He has made incredible progress because of the consistent work he has put into the process. We are extremely grateful to have the opportunity to continue to work with Carlos and make sure he builds all of the physical qualities in the gym that he will need on the trail. This is how Carlos trains for the game, and you can too!</p>
                                <div className={"pb-24"}></div>

                                <h2 className={"text-center text-4xl lg:p-8 px-4 lg:px-0 pb-4"}>January 2023 Awesome Human of the Month</h2>
                                <img className={"rounded lg:w-1/2 mx-auto mb-4"} src={image} alt={"member of the month"}/>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>For those that meet Meera they soon fall in love with her upbeat spirit, great sense of humor and quick wit. She often works out with her Dad Deven who she banters with relentlessly. Despite her physical limitations she is currently thriving against the odds. Meera recently has been awarded with the opportunity to be a sponsored model for an athleticwear company. </p>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>Meera, Deven and the rest of the Parlikar crew are a part of the Kinetic Impact family. Deven shared with us an incredible poem that with his permission we want to share with you below. This embodies how important a supportive environment is to thrive. Our support system and environment play huge roles on our health and the support Meera receives from her loving family and friends speaks volumes. Here Deven’s beautiful poem to his daughter:</p>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto text-center"}><strong>Daddy, Hold My Hand</strong><br/>
                                    (An ode from a grateful father to his beloved daughter)<br/>
                                    <br/>
                                    I am feeling a little wobbly, Daddy, hold my hand,<br/>
                                    My right hand locked with her left, I help her stand,<br/>
                                    Tears brim in my eyes, watching her stumble<br/>
                                    Whilst doing the simplest of tasks, without a grumble,<br/>
                                    Are you ok, my love? I ask,<br/>
                                    I am fine, Daddy, she says, with her happy mask,<br/>
                                    But I know she struggles and she fears,<br/>
                                    That it would break my heart to see her tears,<br/>
                                    We go about our day with joy and glory,<br/>
                                    After all, whose life out there is without a story!<br/>
                                    <br/>
                                    But through her eyes, I see the simplicity of life,<br/>
                                    A world filled with love but nary a strife,<br/>
                                    When she stumbles and falls, I see her fortitude,<br/>
                                    As she dusts off her clothes and walk in gratitude,<br/>
                                    To her I say, my beloved teacher,<br/>
                                    Thank you for making my life so much richer.<br/>
                                    <br/>
                                    I am feeling a little wobbly, Baby, hold my hand,<br/>
                                    Her left hand locked in my right, she helps me stand,<br/>
                                    She gives me strength and she gives me light,<br/>
                                    To be grateful and gracious and to always fight,<br/>
                                    For life’s most precious gifts aren’t to be taken,<br/>
                                    They are to be earned and never forsaken.<br/>
                                    <br/>
                                    She chose me to be her father, not by happenstance,<br/>
                                    For I needed her to raise me, give me a second chance,<br/>
                                    To teach me how to love and to give me hope,<br/>
                                    To help climb life’s, often, slippery slope,<br/>
                                    But this time around, to do it with grace,<br/>
                                    With a grateful smile plastered on my face.<br/>
                                    <br/>
                                    I am feeling a little wobbly, daddy, hold my hand,<br/>
                                    I am feeling a little wobbly, baby, hold my hand,<br/>
                                    With our hands locked together, we both stand,<br/>
                                    And, together, we march hand in hand.<br/>
                                </p>
                                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 px-2 mx-auto"}>How freaking cool is that?? We are so incredibly grateful for the opportunity to help guide these wonderful humans to a healthier and stronger life! :) Stay tuned for the next member of the month as we show how awesome our growing Kinetic Impact family is. </p>







                            </div>
                        </div>
                    </div>
                </div>

            </div>





            <Footer/>
        </div>
    )
}

export default MemberOfTheMonth